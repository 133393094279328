/* General Layout */
body {
    font-family: "Open Sans", Arial, sans-serif;
}

.container {
    text-align: center;
}

h1, h2 {
    color: #50585c;
}

/* Header */
.header {
    background-color: #ffffff;
    border-bottom: 10px solid #007cc1;
    padding: 15px 0;
}

.header .user {
    text-align: right;
    line-height: 35px;
    padding: 5px 0 15px;
}

.loggedinUser {
    padding-right: 10px;
}

.header-inner {
    margin: 0px auto;
    padding: 0 15px;
    width: 1140px;
}

.logo {
    clear: both;
}

.logoutBtn {
    color: #007cc1;
}

.btn {
    padding: 8px 16px;
    background: #f39200;
    border: 1px solid #f39200;
    color: #fff;
    border-radius: 0;
}

.btn.delete {
    background: #737f85;
    border: 1px solid #737f85;
}

.btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled:active {
    background: #c5cacc;
    border-color: #c5cacc;
}

.logoutBtn:hover, .logoutBtn:focus, .logoutBtn:active, .btn:hover, .btn:focus, .btn:active {
    color: #009df4;
    text-decoration: none;
}

.btn:hover, .btn:focus {
    background: #ffa927;
    border-color: #ffa927;
    color: #FFF;
    text-decoration: none;
}

.btn.delete:hover, .btn.delete:focus {
    background: #c5cacc;
    border-color: #c5cacc;
    color: #FFF;
    text-decoration: none;
}

/* Department Area */
main {
    padding: 15px 0 0;
}

.departmentSection {
    padding: 0 10px;
    text-align: left;
}

.departmentSection [type="file"] {
    display: none;
}

.col-md-2 {
    text-align: right;
}
